/* eslint-disable @typescript-eslint/no-explicit-any */

import { useStore } from '@/containers'
import { SelectedIcon } from '@/styles/Icon'
import { nonAccentVietnamese } from '@/utils/converter'
import { useTranslations } from 'next-intl'
import router from 'next/router'
import { useState } from 'react'
import { SearchInput } from '../SearchInput'
import PopupBody from './PopupBody'
import PopupFrame from './PopupFrame'
import PopupTitle from './PopupTitle'

interface ModalChooseLocation {
  productName: string
  productId: string
}

const ModalChooseLocation = () => {
  //   const { systemConfig } = useNotification()
  const {
    setAreaCode,
    isOpenPopupLocation,
    setIsOpenPopupLocation,
    areaCode,
    regions,
  } = useStore()
  const [location, setLocation] = useState('')
  const t = useTranslations('Header')
  return (
    <PopupFrame
      open={isOpenPopupLocation}
      onClose={() => {
        setIsOpenPopupLocation(false)
        setLocation('')
        localStorage?.setItem('area-code', 'true')
      }}
      closeText={t('close')}
      type='choose_location'
      classNameDialog='!z-[9999]'
      className='z-[9999] min-h-[480px] max-w-[520px] bg-white-400'
    >
      <PopupTitle className='px-4 pt-3' type={'choose_location'}>
        {
          <div className='flex flex-wrap'>
            <p className='w-[75%] text-Headline font-medium'>
              {t('selectLocation')}
            </p>
            <SearchInput
              placeholder={t('selectLocationPld')}
              className='mb-3 mt-2'
              value={location}
              onChange={e => setLocation(e.target?.value)}
            />
          </div>
        }
      </PopupTitle>
      <PopupBody>
        <div className='listLocation border-grey-100 mt-[18px] flex h-full max-h-[300px] flex-wrap justify-between gap-y-[12px] overflow-scroll  px-[24px] scrollbar-hide'>
          {regions &&
            regions
              .sort((a, b) => (a.order > b.order ? 1 : -1))
              .filter(store =>
                nonAccentVietnamese(store.name).includes(
                  nonAccentVietnamese(location)
                )
              )
              .map((item, index) => (
                <button
                  key={index}
                  className={`${
                    areaCode === item.code && ' bg-blue-100'
                  } border-grey-100 h-10 w-[48%] border-b-[1px] text-Headline text-dark-400`}
                  onClick={() => {
                    setAreaCode(item.code)
                    setIsOpenPopupLocation(false)
                    setLocation('')
                    localStorage?.setItem('area-code', 'true')
                    router.reload()
                  }}
                >
                  <div className='flex items-center'>
                    <span
                      className={`${
                        areaCode === item.code ? 'w-9' : 'w-9'
                      } flex-auto flex justify-center text-center`}
                    >
                      {areaCode === item.code && <SelectedIcon />}
                    </span>
                    <span
                      className={
                        areaCode === item.code
                          ? 'flex-auto font-semibold'
                          : 'flex-auto'
                      }
                    >
                      {item.name}
                    </span>
                  </div>
                </button>
              ))}
        </div>
      </PopupBody>
    </PopupFrame>
  )
}

export default ModalChooseLocation
